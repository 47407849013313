import React from "react";
import girl from "../../images/promo/girl.png";
import sk_ru from "../../images/partners/Sk_ru.png";
import email from "../../images/footer/email.svg";
import phone from "../../images/footer/phone.svg";
import place from "../../images/footer/place.svg";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

function Footer({ onContactForm }) {
  const location = useLocation();
  let isEngPage = location.pathname.search(/eng/g);
  return (
    <>
        <footer className="footer">
          <a className="footer__logo" href="https://sk.ru/" target="_blank">
            <img className="footer__logo" src={sk_ru} alt="Логотип Сколково"/>
          </a>

          <div className="footer__column">
            <ul className="footer__column-links">
              <li className="footer__link">
                <Link className="footer__link-item" to="/#products">
                  Дорожная аналитика
                </Link>
              </li>
              <li className="footer__link">
                <Link className="footer__link-item" to="/#products">
                  Безопасный водоем
                </Link>
              </li>
              <li className="footer__link">
                <Link className="footer__link-item" to="/#products">
                  Безопасная территория
                </Link>
              </li>
              <li className="footer__link">
                <Link
                  className="footer__link-item"
                  to="/#products"
                >
                  Чистая территория
                </Link>
              </li>
            </ul>

            <ul className="footer__column-links">
              <li className="footer__link">
                <Link className="footer__link-item" to="/#services">
                  Проектирование систем
                </Link>
              </li>
              <li className="footer__link">
                <Link className="footer__link-item" to="/#services">
                  Выполнение НИОКР
                </Link>
              </li>
            </ul>

            <ul className="footer__column-links-info">
              <li className="footer__link-info">
                <img
                  className="footer__info-icon"
                  src={email}
                  alt="Иконка почта"
                />
                <p className="footer__info-text"> info@videofor.ru</p>
              </li>
              <li className="footer__link-info">
                <img
                  className="footer__info-icon"
                  src={phone}
                  alt="Иконка телефон"
                />
                <p className="footer__info-text">+7 (812) 337-67-30</p>
              </li>
              <li className="footer__link-info">
                <img
                  className="footer__info-icon"
                  src={place}
                  alt="Иконка геоточка"
                />
                <p className="footer__info-text">
                  г. Санкт-Петербург, Константиновский пр. 11, оф. 314
                </p>
              </li>
            </ul>
          </div>
          <img className="footer__promo" src={girl} alt="Арт девочка" />
        </footer>
    </>
  );
}

export default Footer;
