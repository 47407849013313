import React from "react";
import trafic from "../../images/products/trafic.png";
import beach from "../../images/products/beach.png";
import security from "../../images/products/security.png";
import monitoring from "../../images/products/monitoring.png";
import { HashLink as Link } from "react-router-hash-link";

function Products() {
  const windowWidtn = window.innerWidth;

  return (
    <section className="products" id="products">
      <h2 className="chapter">НАШИ ПРОДУКТЫ</h2>
      <ul className="cards">
        <li className="card">
          <h3 className="card__title">ДОРОЖНАЯ АНАЛИТИКА</h3>
          {windowWidtn <= 768 ? (
            <p className="card__subtitle">
              Анализ дорожного трафика, измерение дорожных заторов, фиксация
              нарушений ПДД, аварий и ДТП
            </p>
          ) : (
            <p className="card__subtitle">
              Анализ дорожного трафика, измерение дорожных заторов, фиксация
              нарушений ПДД, аварий и ДТП
            </p>
          )}
          <img className="card__image" src={trafic} alt="Дорога" />
          <span className="card__under-image_before"></span>
          <p className="card__under-image">ДОРОЖНАЯ АНАЛИТИКА</p>
        </li>

        <li className="card">
          <h3 className="card__title">БЕЗОПАСНЫЙ ВОДОЕМ</h3>

          {windowWidtn <= 768 ? (
            <p className="card__subtitle">
              Мониторинг инцидентов на водном объекте, фиксация нарушений правил
              плавания маломерных судов, уведомления для спасателей
            </p>
          ) : (
            <p className="card__subtitle">
              Мониторинг инцидентов на водном объекте, фиксация нарушений правил
              плавания маломерных судов, уведомления для спасателей
            </p>
          )}
          <img className="card__image" src={beach} alt="Пляж" />
          <span className="card__under-image_before"></span>

          <p className="card__under-image">БЕЗОПАСНЫЙ ВОДОЕМ</p>
        </li>

        <li className="card">
          <h3 className="card__title">БЕЗОПАСНАЯ ТЕРРИТОРИЯ</h3>

          {windowWidtn <= 768 ? (
            <p className="card__subtitle">
              Охрана объектов культурного наследия от вандализма, контроль за
              соблюдением правил эксплуатации территорий общего пользования
            </p>
          ) : (
            <p className="card__subtitle">
              Охрана объектов культурного наследия от вандализма, контроль за
              соблюдением правил эксплуатации территорий общего пользования
            </p>
          )}
          <img className="card__image" src={security} alt="Вокзал" />
          <span className="card__under-image_before"></span>

          <p className="card__under-image">БЕЗОПАСНАЯ ТЕРРИТОРИЯ</p>
        </li>

        <li className="card">
          <h3 className="card__title">ЧИСТАЯ ТЕРРИТОРИЯ</h3>

          {windowWidtn <= 768 ? (
            <p className="card__subtitle">
              Мониторинг работы служб ЖКХ по уборке территории и содержанию
              площадок для хранения ТБО, фиксация нарушений по обращению с
              отходами
            </p>
          ) : (
            <p className="card__subtitle">
              Мониторинг работы служб ЖКХ по уборке территории и содержанию
              площадок для хранения ТБО, фиксация нарушений по обращению с
              отходами
            </p>
          )}
          <img className="card__image" src={monitoring} alt="Мусорный бак" />
          <span className="card__under-image_before"></span>

          <p className="card__under-image card__under-image_services">
            УМНЫЕ СТАНЦИИ РАЗДЕЛЬНОГО <br />
            СБОРА МУСОРА И ОТХОДОВ
          </p>
        </li>
      </ul>
    </section>
  );
}

export default Products;
