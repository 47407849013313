import React from "react";
import promo from "../../images/promo/promo.png";

function Promo() {
  return (
    <section className="promo">
      <h1 className="promo__title">ДЛЯ БИЗНЕСА, ГОРОДА И ДЛЯ ВАС</h1>
      <p className="promo__subtitle">
        Уникальные решения и программное обеспечение на основе
        <br />
        технологий компьютерного зрения и искусственного интеллекта
      </p>
      <div className="promo__container">
        <img className="promo__picture" src={promo} alt="Арт 'Нейросети'" />
      </div>
    </section>
  );
}

export default Promo;
