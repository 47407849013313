import React from "react";
import coat_of_arms_1440px from "../../images/advantage-icon/coat-of-arms-1440px.png";
import customization_1440px from "../../images/advantage-icon/customization-1440px.png";
import like_1440px from "../../images/advantage-icon/like-1440px.png";
import module_1440px from "../../images/advantage-icon/module-1440px.png";
import support_1440px from "../../images/advantage-icon/support-1440px.png";

function Advantage() {
  const windowWidtn = window.innerWidth;

  return (
    <section className="advantage" id="advantage">
      <h2 className="chapter">ПРЕИМУЩЕСТВА СОТРУДНИЧЕСТВА С НАМИ</h2>
      <ul className="advantage__list">
        <li className="advantage__list-iteam">
          <p className="advantage__percent">&gt;94%</p>

          <p className="advantage__subtitle">Точность работы детекторов</p>
        </li>
        <li className="advantage__list-iteam">
          <img
            className="advantage__icon"
            src={coat_of_arms_1440px}
            alt="Иконка герф РФ"
          />
          <p className="advantage__subtitle">Полностью российская разработка</p>
        </li>
        <li className="advantage__list-iteam">
          <img
            className="advantage__icon"
            src={customization_1440px}
            alt="Иконка настройки"
          />
          <p className="advantage__subtitle">
            Возможность переиспользования существующих камер
          </p>
        </li>
        <li className="advantage__list-iteam">
          <img
            className="advantage__icon"
            src={like_1440px}
            alt="Иконка лайк"
          />
          <p className="advantage__subtitle">
            Интеграция с уличным голосовым оповещением
          </p>
        </li>
        <li className="advantage__list-iteam">
          <img
            className="advantage__icon"
            src={module_1440px}
            alt="Иконка микросхема"
          />
          <p className="advantage__subtitle">
            Возможность обработки видео онлайн / офлайн
          </p>
        </li>
        <li className="advantage__list-iteam">
          <img
            className="advantage__icon"
            src={support_1440px}
            alt="Иконка лампочка"
          />
          <p className="advantage__subtitle">
            Уведомления о событиях в Телеграмм
          </p>
        </li>
      </ul>
    </section>
  );
}

export default Advantage;
