import React from "react";
import ScrollToTop from "@components/ScrollToTop";
import Promo from "@components/promo/Promo";
import Products from "@components/products/Products";
import Advantage from "@components/advantage/Advantage";
import Services from "@components/services/Services";
import Partners from "@components/partners/Partners";

function Layout() {
    return (
        <main className="content">
            <ScrollToTop/>
            <Promo />
            <Products/>
            <Advantage />
            <Services/>
            <Partners/>
        </main>
    );
}

export default Layout;
