import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import Header from "./header/Header";
import Footer from "./footer/Footer";
import ContactForm from "./contactForm/contactForm";
import ServiceDesign from "./ServiceDesign";
import ServiceRD from "./ServiceRD";

import InfoTooltip from "./infoTooltip/InfoTooltip";
import Layout from "@components/layout/Layout";

function App() {
  const location = useLocation();
  const isEngPage = location.pathname.search(/eng/g);

  const [isContactFormPopupOpen, setContactFormPopupOpen] =
    React.useState(false);
  const [isInfoTooltipPopupOpen, setInfoTooltipPopupOpen] =
    React.useState(false);
  const [titleInfoTooltipPopup, setTitleInfoTooltipPopup] = React.useState("");

  //Обработчики открытия попапов
  function handleContactFormPopupOpen() {
    setContactFormPopupOpen(true);
  }
  function handleInfoTooltipPopupOpen() {
    setInfoTooltipPopupOpen(true);
  }

  //Обработчик закрытия попапов
  function closeAllPopups() {
    setContactFormPopupOpen(false);
  }
  function handleInfoTooltipPopupClose() {
    setInfoTooltipPopupOpen(false);
  }

  function handleInfoTooltipContent(title) {
    setTitleInfoTooltipPopup(title);
  }

  //подписаться на блог по email - сообщение в телеграмм
  function handleSubmitInfoFormBlog(info) {
    TelegramApi.sendMessageInfoEmail(info)
      .then(() => {
        handleInfoTooltipContent(
          `${
            isEngPage !== 1
              ? "МЫ СООБЩИМ ВАМ О НОВЫХ СТАТЬЯХ"
              : "WE WILL INFORM  YOU ABOUT NEW ARTICLES"
          }`
        );
        handleInfoTooltipPopupOpen();
        closeAllPopups();
      })
      .catch((err) => {
        handleInfoTooltipContent("Ooops" + err);
        handleInfoTooltipPopupOpen();
        console.log(err);
      });
  }

  //запросить информацию по email - сообщение в телеграмм
  function handleSubmitInfoForm(info) {
    TelegramApi.sendMessageInfoEmail(info)
      .then(() => {
        handleInfoTooltipContent(
          `${
            isEngPage !== 1
              ? "СКОРО С ВАМИ СВЯЖЕМСЯ"
              : "WE WILL CONTACT YOU SOON"
          }`
        );
        handleInfoTooltipPopupOpen();
        closeAllPopups();
      })
      .catch((err) => {
        handleInfoTooltipContent("Ooops" + err);
        handleInfoTooltipPopupOpen();
        console.log(err);
      });
  }

  //форма "свяжитесь со мной"- сообщение в телеграмм
  function handleSubmitContactForm(info) {
    TelegramApi.sendMessageContactPhoneEng(info)
      .then(() => {
        handleInfoTooltipContent(
          `${
            isEngPage !== 1
              ? "СКОРО С ВАМИ СВЯЖЕМСЯ"
              : "WE WILL CONTACT YOU SOON"
          }`
        );
        handleInfoTooltipPopupOpen();
        closeAllPopups();
      })
      .catch((err) => {
        handleInfoTooltipContent("Ooops" + err);
        handleInfoTooltipPopupOpen();
        console.log(err);
      });
  }

  return (
    <div className="page">
      <Header />

      <Routes>
        <Route exact path="/" element={<Layout />}/>


        {/*<Route path="/eng">*/}
        {/*  <main className="content">*/}
        {/*    <ScrollToTop />*/}
        {/*    <PromoEng onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <ProductsEng />*/}
        {/*    <AdvantageEng onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <ServicesEng />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*    <BlogEng />*/}
        {/*    <PartnersEng />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/blog-beach">*/}
        {/*  <ScrollToTop />*/}
        {/*  <main className="content">*/}
        {/*    <BlogBeach />*/}
        {/*    <Info*/}
        {/*      title={"Получать новые публикации"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoFormBlog}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/blog-recycle">*/}
        {/*  <ScrollToTop />*/}
        {/*  <main className="content">*/}
        {/*    <BlogRecycle />*/}
        {/*    <Info*/}
        {/*      title={"Получать новые публикации"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoFormBlog}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/blog-traffic">*/}
        {/*  <ScrollToTop />*/}

        {/*  <main className="content">*/}
        {/*    <BlogTraffic />*/}
        {/*    <Info*/}
        {/*      title={"Получать новые публикации"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoFormBlog}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/smart-city">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartCity onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <Info*/}
        {/*      title={"Запросить подробную информацию"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/smart-city/eng">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartCityEng onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/smart-beach">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartBeach onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <Info*/}
        {/*      title={"Запросить подробную информацию"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/smart-beach/eng">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartBeachEng*/}
        {/*      onContactForm={handleContactFormPopupOpen}*/}
        {/*    />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/smart-space">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartSpace onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <Info*/}
        {/*      title={"Запросить подробную информацию"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/smart-space/eng">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartSpaceEng*/}
        {/*      onContactForm={handleContactFormPopupOpen}*/}
        {/*    />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/smart-waste-sites">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartWasteSites*/}
        {/*      onContactForm={handleContactFormPopupOpen}*/}
        {/*    />*/}
        {/*    <Info*/}
        {/*      title={"Запросить подробную информацию"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="/smart-waste-sites/eng">*/}
        {/*  <main className="content">*/}
        {/*    <AdvantageSmartWasteSitesEng*/}
        {/*      onContactForm={handleContactFormPopupOpen}*/}
        {/*    />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/design-service" element={<ServiceDesign/>}/>*/}

        {/*<Route path="/design-service/eng">*/}
        {/*  <main className="content">*/}
        {/*    <ServiceDesignEng onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route exact path="/r-d-service" element={<ServiceRD/>}/>*/}

        {/*<Route path="/r-d-service/eng">*/}
        {/*  <main className="content">*/}
        {/*    <ServiceRDEng onContactForm={handleContactFormPopupOpen} />*/}
        {/*    <InfoEng*/}
        {/*      title={"Request detailed information"}*/}
        {/*      onSubmitInfoForm={handleSubmitInfoForm}*/}
        {/*    />*/}
        {/*  </main>*/}
        {/*</Route>*/}

        {/*<Route path="*">*/}
        {/*  <PageNotFound />*/}
        {/*</Route>*/}
      </Routes>
      <Footer onContactForm={handleContactFormPopupOpen} />

      <ContactForm
        isOpen={isContactFormPopupOpen}
        onClose={closeAllPopups}
        handleSubmit={closeAllPopups}
        onSubmitContactForm={handleSubmitContactForm}
      />
      <InfoTooltip
        isOpen={isInfoTooltipPopupOpen}
        onClose={handleInfoTooltipPopupClose}
        title={titleInfoTooltipPopup}
      />
    </div>
  );
}

export default App;
